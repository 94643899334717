import {
  AspectRatio,
  Avatar,
  Box,
  Center,
  Circle,
  CircularProgress,
  Flex,
  SimpleGrid,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import FAIcon from "@components/FAIcon";
import VideoPlayer from "components/VideoPlayer";
import useAPI from "hooks/api";
import getPublicDownloadUrl from "libs/get-public-download-url";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import { useFirebase } from "context/firebase.context";
import canonicalDate from "libs/canonical-date";
import CreatorWallet from "components/CreatorWallet";

const FollowersList = ({ id }) => {
  const api = useAPI();
  const [followers, setFollowers] = useState([]);

  const loadCreatorFollowers = useCallback(async () => {
    const result = await api.getCreatorFollowers(id);
    if (result) setFollowers(result.data);
  }, [api, id]);

  useEffect(() => {
    loadCreatorFollowers();
  }, [loadCreatorFollowers]);

  return (
    <SimpleGrid spacing={2}>
      {followers.map((follower) => {
        return (
          <Flex
            justifyContent={"space-between"}
            key={_.get(follower, "user.id")}
            alignItems={"center"}
          >
            <Flex alignItems={"center"} columnGap={4}>
              <Circle
                size={12}
                backgroundColor={"black"}
                backgroundImage={
                  _.get(follower, "user.picture")
                    ? getPublicDownloadUrl(_.get(follower, "user.picture"))
                    : null
                }
                backgroundPosition={"center"}
                backgroundSize={"cover"}
              ></Circle>
              <Box>
                <Text fontWeight={"semibold"}>
                  {_.get(follower, "user.displayName")}
                </Text>
                <Text color={"gray.400"} fontSize={"sm"}>
                  {dayjs
                    .unix(_.get(follower, "followedAt") / 1000)
                    .format("YYYY-MM-DD HH:mm:ss")}
                </Text>
              </Box>
            </Flex>
            <Flex columnGap={4} fontSize={"lg"}>
              <FAIcon cursor={"pointer"} group="solid" name={"comment"} />
              <FAIcon cursor={"pointer"} group="solid" name={"circle-info"} />
            </Flex>
          </Flex>
        );
      })}
    </SimpleGrid>
  );
};

const VideosList = ({ id, onRemove }) => {
  const { user } = useFirebase();
  const [nextCursor, setNextCursor] = useState(null);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const bottomDetectorRef = useRef(null);
  const api = useAPI();
  const [creatorVideos, setCreatorVideos] = useState([]);
  const [activeVideo, setActiveVideo] = useState(null);

  const loadCreatorVideos = useCallback(
    async (next = null) => {
      if (loadingVideos) return;
      setLoadingVideos(true);
      const listCreatorVideosResult = await api.getCreatorVideos(id, { next });
      const nextPageVideos = _.get(listCreatorVideosResult, "data", []);
      if (nextPageVideos.length === 0) {
        setLoadingVideos(false);
        return;
      }
      setCreatorVideos([
        ...creatorVideos,
        ..._.get(listCreatorVideosResult, "data", []),
      ]);
      setNextCursor(_.get(listCreatorVideosResult, "paginator.next"));
      setLoadingVideos(false);
    },
    [api, id, setCreatorVideos, creatorVideos, loadingVideos],
  );

  const playVideo = useCallback((video) => {
    setActiveVideo(video);
  }, []);

  const removeVideoFromList = useCallback(
    (id) => () => {
      const clone = creatorVideos.slice();
      const index = clone.findIndex((video) => video.id === id);
      if (index !== -1) {
        clone.splice(index, 1);
        setCreatorVideos(clone);
        onRemove();
      }
    },
    [creatorVideos, onRemove],
  );

  useEffect(() => {
    if (user) {
      loadCreatorVideos();
    }
  }, [user]); // eslint-disable-line

  useEffect(() => {
    if (bottomDetectorRef.current) {
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (entries[0].intersectionRatio >= 1) {
          if (!nextCursor) return;
          loadCreatorVideos(nextCursor);
        }
      });
      intersectionObserver.observe(bottomDetectorRef.current);
      return () => {
        intersectionObserver.disconnect();
      };
    }
  }, [bottomDetectorRef, nextCursor]); // eslint-disable-line

  return (
    <>
      <SimpleGrid columns={3} spacing={1}>
        {creatorVideos.map((video) => {
          return (
            <Box
              cursor={"pointer"}
              id={_.get(video, "id")}
              key={_.get(video, "id")}
              onClick={() => playVideo(video)}
            >
              <AspectRatio ratio={9 / 16}>
                <Box
                  position={"relative"}
                  backgroundSize={"cover"}
                  backgroundPosition={"center"}
                  backgroundImage={
                    _.get(video, "thumbnail", null)
                      ? `url("${_.get(video, "thumbnail", null)}")`
                      : null
                  }
                  backgroundColor={"black"}
                >
                  <SimpleGrid
                    width={"full"}
                    position={"absolute"}
                    bottom={0}
                    left={0}
                    backgroundColor={"blackAlpha.600"}
                    paddingY={1}
                    paddingX={2}
                  >
                    <Flex alignItems={"center"} fontSize={"sm"}>
                      <FAIcon name="gem" group="solid"></FAIcon>
                      <Text marginLeft={1}>{_.get(video, "price", 0)}</Text>
                    </Flex>
                    <Flex alignItems={"center"} fontSize={"sm"}>
                      <FAIcon name="heart" group="solid"></FAIcon>
                      <Text marginLeft={1}>
                        {_.get(video, "likedTotal", 0)}
                      </Text>
                    </Flex>
                    <Flex alignItems={"center"} fontSize={"sm"}>
                      {_.get(video, "status") === "encoding" && (
                        <>
                          <FAIcon name="timer"></FAIcon>
                          <Text marginLeft={1}>編碼中</Text>
                        </>
                      )}
                      {_.get(video, "status") === "review" && (
                        <>
                          <FAIcon name="eye"></FAIcon>
                          <Text marginLeft={1}>等待審核</Text>
                        </>
                      )}
                      {_.get(video, "status") === "reviewing" && (
                        <>
                          <FAIcon name="pen"></FAIcon>
                          <Text marginLeft={1}>審核中</Text>
                        </>
                      )}
                      {_.get(video, "status") === "released" && (
                        <>
                          <FAIcon name="check" color={"green.200"}></FAIcon>
                          <Text color={"green.200"} marginLeft={1}>
                            已放行
                          </Text>
                        </>
                      )}
                      {_.get(video, "status") === "rejected" && (
                        <>
                          <FAIcon name="xmark" color={"red.200"}></FAIcon>
                          <Text color={"red.200"} marginLeft={1}>
                            已拒絕
                          </Text>
                        </>
                      )}
                    </Flex>
                  </SimpleGrid>
                </Box>
              </AspectRatio>
            </Box>
          );
        })}
      </SimpleGrid>
      {activeVideo && (
        <Box
          position={"fixed"}
          top={0}
          left={0}
          height={"100vh"}
          width={"full"}
          zIndex={101}
        >
          <VideoPlayer
            autoPlay={true}
            creatorId={_.get(activeVideo, "creatorId", null)}
            video={activeVideo}
            streamUrl={_.get(activeVideo, "playbacks[0].streamUrl", null)}
            downloadUrl={_.get(
              activeVideo,
              "playbacks[1].streamUrl",
              _.get(activeVideo, "playbacks[0].streamUrl", null),
            )}
            onClose={() => setActiveVideo(null)}
            onDelete={removeVideoFromList(activeVideo.id)}
            options={{
              close: true,
              trash: _.get(activeVideo, "status", null) === "rejected",
            }}
          />
        </Box>
      )}
      <Box
        id="bottom-detector"
        height={"1px"}
        ref={bottomDetectorRef}
        width={"full"}
      />
      {loadingVideos && (
        <Flex padding={4} justifyContent={"center"} alignItems={"center"}>
          <Spinner />
        </Flex>
      )}
    </>
  );
};

const Chats = ({ creator }) => {
  const api = useAPI();
  const [chats, setChats] = useState(null);

  const loadChats = useCallback(async () => {
    if (!creator) return;
    const response = await api.getCreatorChats(creator.id);
    setChats(response?.data);
  }, [api, creator]);

  useEffect(() => {
    loadChats();
  }, [loadChats]);

  return (
    <VStack align="stretch">
      {chats === null && (
        <Center mt={6}>
          <CircularProgress isIndeterminate />
        </Center>
      )}
      {chats?.map(({ id, lastMessage, user }) => (
        <Flex key={id} p={3} borderBottomWidth={1} gap={3} align="center">
          <Avatar src={getPublicDownloadUrl(user?.picture)} />
          <Box flex={1}>
            <Text pb={1}>{user.displayName}</Text>
            <Text noOfLines={1}>
              {lastMessage.sender.type === "user"
                ? "用戶"
                : creator?.displayName}
              ： {lastMessage?.text}
              {lastMessage.type !== "text" && lastMessage.text && " | "}
              {lastMessage.type === "gift" && "禮物"}
              {lastMessage.type === "video" && "影片"}
              {lastMessage.type === "audio" && "錄音"}
            </Text>
          </Box>
          <Text>{canonicalDate(lastMessage.createdAt)}</Text>
        </Flex>
      ))}
    </VStack>
  );
};

export default function CreatorFetchPage() {
  const api = useAPI();
  const params = useParams();
  const { id } = params;
  const [creator, setCreator] = useState(null);

  const loadCreator = useCallback(async () => {
    const creator = await api.getCreator(id);
    setCreator(creator);
  }, [api, id]);

  // when removing videos, reduce videos total by 1
  const reduceVideosTotal = useCallback(() => {
    const videosTotal = _.get(creator, "videosTotal", 0);
    setCreator({
      ...creator,
      videosTotal: videosTotal - 1,
    });
  }, [creator]);

  useEffect(() => {
    loadCreator();
  }, [loadCreator]);

  return (
    <MainLayout>
      <SimpleGrid paddingY={8}>
        <Flex
          rowGap={4}
          flexDirection={"column"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Flex width={"full"} alignItems={"center"} paddingX={8} columnGap={8}>
            <Circle
              size={24}
              backgroundColor={"black"}
              backgroundImage={
                _.get(creator, "picture")
                  ? `url('${getPublicDownloadUrl(_.get(creator, "picture"))}')`
                  : null
              }
              backgroundSize={"cover"}
              backgroundPosition={"center"}
            />
            <SimpleGrid
              width={"full"}
              borderRadius={"md"}
              marginTop={4}
              columns={3}
              paddingY={4}
            >
              <Flex flexDirection={"column"} rowGap={2} alignItems={"center"}>
                <Text fontSize={"xl"}>
                  {_.get(creator, "followersTotal", 0)}
                </Text>
                <Text fontSize={"xs"}>追蹤數</Text>
              </Flex>
              <Flex flexDirection={"column"} rowGap={2} alignItems={"center"}>
                <Text fontSize={"xl"}>{_.get(creator, "videosTotal", 0)}</Text>
                <Text fontSize={"xs"}>上架影片數</Text>
              </Flex>
              <Flex flexDirection={"column"} rowGap={2} alignItems={"center"}>
                <Text fontSize={"xl"}>
                  {_.get(creator, "points.balance", 0)}
                </Text>
                <Text fontSize={"xs"}>鑽石數</Text>
              </Flex>
            </SimpleGrid>
          </Flex>
          <Flex width={"full"} paddingX={8}>
            <Box>
              <Text fontWeight={"bold"}>
                {_.get(creator, "displayName", "未命名創作者")}
              </Text>
              <Text fontSize={"sm"}>
                @{_.get(creator, "slug", "anonymouse")}
              </Text>
            </Box>
            <Box>{_.get(creator, "description")}</Box>
          </Flex>
        </Flex>
        <Tabs isLazy={true} marginTop={8}>
          <TabList>
            <Tab>影片</Tab>
            <Tab>追蹤者</Tab>
            <Tab>錢包紀錄</Tab>
            <Tab>聊天室</Tab>
          </TabList>

          <TabPanels>
            <TabPanel padding={0}>
              <VideosList id={id} onRemove={reduceVideosTotal} />
            </TabPanel>
            <TabPanel>
              <FollowersList id={id} />
            </TabPanel>
            <TabPanel>
              <CreatorWallet creator={creator} />
            </TabPanel>
            <TabPanel p={0}>
              {/* @todo: full chat record */}
              <Chats creator={creator} />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </SimpleGrid>
    </MainLayout>
  );
}
