import {
  Box,
  Button,
  Circle,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  useToast,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import dayjs from "dayjs";
import useAPI from "hooks/api";
import _ from "lodash";
import { useCallback, useRef, useState } from "react";

const txTypesMapping = {
  CHATROOM_MESSAGE: "聊天室傳送訊息",
  CHATROOM_GIFT: "聊天室傳送禮物",
  CHATROOM_UNLOC_IMAGE: "聊天室解鎖圖片",
  CHATROOM_UNLCOK_VIDEO: "聊天室解鎖影片",
  UNLOCK_VIDEO: "解鎖影片",
  DEPOSIT: "儲值",
  COMPENSATION: "補償",
  DAILY_REWARD: "每日獎勵",
  POINT_DEPOSIT: "儲值",
};

export default function TransactionSearchPage() {
  const api = useAPI();
  const inputRef = useRef(null);
  const toast = useToast();
  const [transaction, setTransaction] = useState(null);
  const search = useCallback(async () => {
    if (!inputRef.current) return;
    const depositId = inputRef.current.value.trim();
    if (!depositId) return;
    const response = await api.getTransaction(depositId);
    const { error = null } = response;
    if (!error) {
      setTransaction(response);
      return;
    }
    toast({
      title: "找不到指定儲值訂單",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  }, [api, setTransaction, toast]);

  const points = _.get(transaction, "points", 0);
  const type = _.get(transaction, "type", "");

  return (
    <MainLayout>
      <SimpleGrid spacing={2} paddingX={4} marginTop={4}>
        <FormControl>
          <Input placeholder="訂單 ID" ref={inputRef} />
          {/* <FormErrorMessage>We'll never share your email.</FormErrorMessage> */}
        </FormControl>
        <Button onClick={search} colorScheme={"blue"}>
          搜尋
        </Button>
        {transaction && (
          <Box padding={4} backgroundColor={"gray.700"} borderRadius={"md"}>
            <SimpleGrid spacing={4}>
              <Flex alignItems={"center"} columnGap={2}>
                <Circle size={8} backgroundColor={"black"}></Circle>
                <Text>使用者 ID: {_.get(transaction, "userId", "--")}</Text>
              </Flex>
              <FormControl>
                <FormLabel>交易 ID</FormLabel>
                <Input
                  readOnly={true}
                  isDisabled={true}
                  value={_.get(transaction, "id")}
                />
              </FormControl>
              <FormControl>
                <FormLabel>交易類型</FormLabel>
                <Input
                  readOnly={true}
                  isDisabled={true}
                  value={txTypesMapping[type] ?? type}
                />
              </FormControl>
              <FormControl>
                <FormLabel>鑽石變動</FormLabel>
                <Input
                  type="text"
                  readOnly={true}
                  isDisabled={true}
                  value={points > 0 ? `+${points}` : points}
                />
              </FormControl>
              <FormControl>
                <FormLabel>交易時間</FormLabel>
                <Input
                  readOnly={true}
                  isDisabled={true}
                  value={dayjs
                    .unix(_.get(transaction, "createdAt", "") / 1000)
                    .format("YYYY MM DD HH:mm:ss (dddd)")
                    .toString()}
                />
              </FormControl>
            </SimpleGrid>
          </Box>
        )}
      </SimpleGrid>
    </MainLayout>
  );
}
