import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Image,
  Square,
  Text,
  VStack,
  useDisclosure,
} from "@chakra-ui/react";
import FAIcon from "@components/FAIcon";
import { useFirebase } from "context/firebase.context";
import { signOut } from "firebase/auth";
import { Link, useNavigate } from "react-router-dom";

export default function Menu({ links }) {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { auth } = useFirebase();

  return (
    <Flex
      alignItems="center"
      height={16}
      position="sticky"
      top={0}
      px={4}
      borderBottomWidth="1px"
      borderColor="gray.700"
      bg="gray.800"
      zIndex={10}
    >
      <Button
        variant="ghost"
        onClick={onOpen}
        display={{ base: "block", md: "none" }}
      >
        <FAIcon name="bars" />
      </Button>
      <Image
        cursor={"pointer"}
        onClick={() => navigate("/")}
        marginLeft={2}
        marginTop={1}
        src="/images/logo.png"
        height={6}
      />
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerBody>
            <VStack align="stretch" pt={12} height="100%">
              {links.map(({ name, path, icon }) => (
                <Flex key={name} as={Link} alignItems="center" to={path}>
                  <Square size={10} fontSize="xl">
                    <FAIcon name={icon} />
                  </Square>
                  <Text ml={2} fontWeight="semibold">
                    {name}
                  </Text>
                </Flex>
              ))}
              <VStack flex={1} justify="flex-end" align="stretch" pb={5}>
                <Button onClick={() => signOut(auth)}>登出</Button>
              </VStack>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
}
