import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Flex,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Select,
  VStack,
  useToast,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import useAPI from "hooks/api";
import { useCallback, useEffect, useState } from "react";

export default function DeveloperPage() {
  const api = useAPI();
  const toast = useToast();
  const [accounts, setAccounts] = useState(null);
  const [target, setTarget] = useState("");
  const [userId, setUserId] = useState("");
  const [value, setValue] = useState(1000);

  const resetVideoSearchEngine = useCallback(async () => {
    try {
      await api.resetVideoSearchEngine();
      toast({ title: "重設成功", status: "success" });
    } catch (e) {
      toast({ title: "重設失敗", status: "error" });
    }
  }, [api, toast]);

  const resetCreatorSearchEngine = useCallback(async () => {
    try {
      await api.resetCreatorSearchEngine();
      toast({ title: "重設成功", status: "success" });
    } catch (e) {
      toast({ title: "重設失敗", status: "error" });
    }
  }, [api, toast]);

  const grantPoints = useCallback(async () => {
    await api.grantTestAccountPoints(target, value);
    toast({ title: `成功發送 ${value} 鑽石`, status: "success" });
  }, [api, target, toast, value]);

  const deleteVIP = useCallback(async () => {
    await api.deleteVIP(userId);
    toast({ title: "已刪除用戶 VIP", status: "success" });
  }, [api, toast, userId]);

  useEffect(() => {
    api.getTestAccounts().then((response) => setAccounts(response?.data));
  }, [api]);

  return (
    <MainLayout>
      <Alert status="error" alignSelf="stretch">
        <VStack align="flex-start">
          <Flex>
            <AlertIcon />
            <AlertTitle>這裡是開發人員專用選項！</AlertTitle>
          </Flex>

          <AlertDescription fontSize="sm">
            如果不小心誤點，請按上一頁離開假裝你什麼都沒有看到
          </AlertDescription>
        </VStack>
      </Alert>
      <Box p={5}>
        <Heading size="lg" mb={3}>
          搜尋引擎
        </Heading>
        <VStack gap={4} align="start">
          <Button onClick={resetVideoSearchEngine}>重設影片引擎</Button>
          <Button onClick={resetCreatorSearchEngine}>重設創作者引擎</Button>
        </VStack>
        <Heading size="lg" mt={12} mb={3}>
          發送鑽石 (測試帳號)
        </Heading>
        {accounts == null ? (
          "讀取中..."
        ) : (
          <Flex gap={1}>
            <InputGroup>
              <InputLeftAddon>給</InputLeftAddon>
              <Select
                onChange={(e) => setTarget(e.target.value)}
                value={target}
              >
                <option>選擇帳號</option>
                {accounts?.map((account, index) => (
                  <option key={index} value={index}>
                    {account.displayName}
                  </option>
                ))}
              </Select>
            </InputGroup>
            <InputGroup>
              <Input value={value} onChange={(e) => setValue(e.target.value)} />
              <InputRightAddon>鑽石</InputRightAddon>
            </InputGroup>
            <Button colorScheme="red" onClick={grantPoints}>
              確認
            </Button>
          </Flex>
        )}
        <Heading size="lg" mt={12} mb={3}>
          刪除用戶 VIP
        </Heading>
        <Box>
          <InputGroup>
            <InputLeftAddon>用戶 ID</InputLeftAddon>
            <Input value={userId} onChange={(e) => setUserId(e.target.value)} />
          </InputGroup>
          <Button colorScheme="red" onClick={deleteVIP} mt={4}>
            確認
          </Button>
        </Box>
      </Box>
    </MainLayout>
  );
}
