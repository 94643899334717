import { Flex, AspectRatio } from "@chakra-ui/react";
import FAIcon from "./FAIcon";
import { useMemo } from "react";

const AddItem = ({ ratio, ...props }) => {
  const Wrapper = useMemo(() => (ratio == null ? Flex : AspectRatio), [ratio]);

  return (
    <Wrapper
      role="button"
      borderWidth={3}
      borderStyle="dotted"
      borderColor="gray.300"
      ratio={ratio}
      {...props}
    >
      <FAIcon name="plus" fontSize="3xl" m="auto" />
    </Wrapper>
  );
};

export default AddItem;
