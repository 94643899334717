import { Center, CircularProgress, Flex, Text, VStack } from "@chakra-ui/react";
import useAPI from "hooks/api";
import canonicalDate from "libs/canonical-date";
import numeral from "numeral";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Cell, Pie, PieChart } from "recharts";

const INCOME_COLORS = [
  "#c6ff00",
  "#7cb342",
  "#4caf50",
  "#2196f3",
  "#3949ab",
  "#512da8",
  "#e91e63",
  "#c62828",
];

const typeNameMapping = {
  CHATROOM_MESSAGE: "聊天訊息",
  CHATROOM_GIFT: "聊天禮物",
  CHATROOM_UNLOCK_VIDEO: "聊天影片",
  CHATROOM_UNLOCK_IMAGE: "聊天圖片",
  UNLOCK_VIDEO: "解鎖影片",
};

const CreatorWallet = ({ creator }) => {
  const api = useAPI();
  const [transactions, setTransactions] = useState(null);
  const [analytics, setAnalytics] = useState(null);

  const loadTransactions = useCallback(async () => {
    if (!creator) return;
    const txs = await api.getCreatorTransactions(creator.id);
    const txAnalytics = await api.getCreatorTxsAnalytics(creator.id);
    setTransactions(txs?.data);
    setAnalytics(txAnalytics.data);
  }, [api, creator]);

  const totalIncome = useMemo(() => {
    if (!analytics) return null;
    return Object.values(analytics).reduce((acc, cur) => acc + cur.total, 0);
  }, [analytics]);

  const data = useMemo(() => {
    if (!analytics) return null;

    return Object.entries(analytics).map(([key, d], index) => ({
      type: key,
      value: d.total,
      count: d.count,
      color: INCOME_COLORS[index % 8],
    }));
  }, [analytics]);

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);

  return (
    <VStack align="stretch">
      {transactions === null && (
        <Center mt={6}>
          <CircularProgress isIndeterminate />
        </Center>
      )}
      {data && (
        <VStack align="center">
          <Text align="center">總鑽石收入：{totalIncome}</Text>
          <PieChart width={360} height={360}>
            <Pie
              label={({ index }) => {
                const { type, value } = data[index];
                const number = numeral(value).format("0.00a");
                return `${typeNameMapping[type]} ${number}(${((value / totalIncome) * 100).toFixed(1)}%)`;
              }}
              data={data}
              cx={180}
              cy={180}
              startAngle={90}
              endAngle={450}
              innerRadius={55}
              outerRadius={70}
              fill="#8884d8"
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={data[index].color} />
              ))}
            </Pie>
          </PieChart>
        </VStack>
      )}
      {transactions?.map(({ id, type, createdAt, points }) => (
        <Flex key={id} p={3} borderBottomWidth={1} gap={3} align="center">
          <Text>{points}</Text>
          <Text flex={1}>{type}</Text>
          <Text>{canonicalDate(createdAt)}</Text>
        </Flex>
      ))}
    </VStack>
  );
};

export default CreatorWallet;
