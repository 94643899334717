import MustAdminLayout from "layouts/must-admin.layout";
import CreatorsIndexPage from "pages/creators";
import CreatorFetchPage from "pages/creators/fetch";
import ReviewVideoPage from "pages/review";
import ExplorePage from "pages/explore";
import AnnouncementsPage from "pages/announcements";
import NewAnnouncementPage from "pages/announcements/new";
import EditAnnouncementPage from "pages/announcements/edit";
import DeveloerPage from "pages/developer";
import { Route, Routes } from "react-router-dom";
import UserSearchPage from "pages/users/search";
import DepositSearchPage from "pages/deposits/search";
import TransactionSearchPage from "pages/transactions/search";
import ClaimPreRegistrationPage from "pages/pre-registration/claim";
import AnalyticsPage from "pages/analytics";
import GuestModePage from "pages/guest-mode";
import CreateCreatorPage from "pages/creators/create";

export default function GeneralRoutes() {
  return (
    <Routes>
      <Route path="/" element={<MustAdminLayout />}>
        <Route index element={<AnalyticsPage />} />
        <Route path="review/videos" element={<ReviewVideoPage />} />
        <Route path="guest-mode" element={<GuestModePage />} />
        <Route path="creators" element={<CreatorsIndexPage />} />
        <Route path="creators/:id" element={<CreatorFetchPage />} />
        <Route path="creators/new" element={<CreateCreatorPage />} />
        <Route path="users/search" element={<UserSearchPage />} />
        <Route path="deposits/search" element={<DepositSearchPage />} />
        <Route path="transactions/search" element={<TransactionSearchPage />} />
        <Route path="announcements" element={<AnnouncementsPage />} />
        <Route path="explore" element={<ExplorePage />} />
        <Route path="announcements/new" element={<NewAnnouncementPage />} />
        <Route
          path="announcements/:id/edit"
          element={<EditAnnouncementPage />}
        />
        <Route path="developer" element={<DeveloerPage />} />
        <Route
          path="pre-registration-reward/claim"
          element={<ClaimPreRegistrationPage />}
        />
        <Route path="analytics" element={<AnalyticsPage />} />
      </Route>
    </Routes>
  );
}
