import { Box, Flex, VStack } from "@chakra-ui/react";
import Menu from "components/Menu";
import Sidebar from "components/Sidebar";

const links = [
  {
    name: "統計數據",
    icon: "chart-simple",
    path: "/analytics",
  },

  {
    name: "影片審核",
    icon: "eye",
    path: "/review/videos",
  },
  {
    name: "訪客模式管理",
    icon: "user",
    path: "/guest-mode",
  },
  {
    name: "公告管理",
    icon: "megaphone",
    path: "/announcements",
  },
  {
    name: "探索頁面管理",
    icon: "compass",
    path: "/explore",
  },
  {
    name: "創作者管理",
    icon: "pen",
    path: "/creators",
  },
  {
    name: "使用者查詢",
    icon: "users",
    path: "/users/search",
  },
  {
    name: "儲值查詢",
    icon: "users",
    path: "/deposits/search",
  },
  {
    name: "鑽石交易紀錄查詢",
    icon: "gem",
    path: "/transactions/search",
  },
  {
    name: "KYC 驗證",
    icon: "address-card",
    path: "/creators",
  },
  {
    name: "事前登錄獎勵發放",
    icon: "gift",
    path: "/pre-registration-reward/claim",
  },
  {
    name: "開發者工具",
    icon: "gear",
    path: "/developer",
  },
];

export default function MainLayout({ children, ...props }) {
  return (
    <VStack minHeight="100vh" align="stretch" gap={0}>
      <Menu links={links} />
      <Flex flex={1}>
        <Sidebar links={links} />
        <Box flex={1}>
          <Box {...props}>{children}</Box>
        </Box>
      </Flex>
    </VStack>
  );
}
