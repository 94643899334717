const getPublicDownloadUrl = (filename) => {
  if (!filename) return "";
  const pathname = filename.split("?")[0];
  return `${
    process.env.REACT_APP_EMULATOR_ENABLE === "true"
      ? "http://localhost:9199"
      : "https://firebasestorage.googleapis.com"
  }/v0/b/${
    process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
  }/o/${encodeURIComponent(pathname)}?alt=media`;
};

export default getPublicDownloadUrl;
