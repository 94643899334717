import MainLayout from "@layouts/main.layout";
import AnnouncementsEditor from "components/AnnoucementEditor";
import useAPI from "hooks/api";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function EditAnnouncementPage() {
  const { id } = useParams();
  const api = useAPI();
  const [annoucement, setAnnouncement] = useState();
  const editAnnouncement = useCallback(
    async (updated) => {
      await api.updateNotification(annoucement.id, updated);
    },
    [annoucement, api],
  );

  useEffect(() => {
    api.getNotification(id).then(setAnnouncement);
  }, [api, id]);
  return (
    <MainLayout>
      {annoucement && (
        <AnnouncementsEditor
          onSend={editAnnouncement}
          annoucement={annoucement}
        />
      )}
    </MainLayout>
  );
}
