import { Box, Button, Flex, Square, Text, VStack } from "@chakra-ui/react";
import FAIcon from "./FAIcon";
import { Link } from "react-router-dom";
import { useFirebase } from "context/firebase.context";
import { signOut } from "firebase/auth";

const Sidebar = ({ links }) => {
  const { auth } = useFirebase();
  return (
    <Box
      display={{ base: "none", md: "block" }}
      width={360}
      height="calc(100vh - 4rem)"
      top={16}
      position="sticky"
      bg="gray.700"
    >
      <VStack align="stretch" pt={6} height="100%" p={6}>
        {links.map(({ name, path, icon }) => (
          <Flex key={name} as={Link} alignItems="center" to={path}>
            <Square size={10} fontSize="xl">
              <FAIcon name={icon} />
            </Square>
            <Text ml={2} fontWeight="semibold">
              {name}
            </Text>
          </Flex>
        ))}
        <VStack flex={1} justify="flex-end" align="stretch" pb={5}>
          <Button onClick={() => signOut(auth)}>登出</Button>
        </VStack>
      </VStack>
    </Box>
  );
};

export default Sidebar;
