import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import { useCallback, useState } from "react";
import _ from "lodash";
import useAPI from "hooks/api";

export default function CreateCreatorPage() {
  const api = useAPI();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [userIdentifier, setUserIndentifier] = useState("");
  const [searching, setSearching] = useState(false);

  const [displayName, setDisplayName] = useState("");
  const [displayNameErrorMessage, setDisplayNameErrorMessage] = useState("");
  const [userId, setUserId] = useState("");
  const [userIdErrorMessage, setUserIdErrorMessage] = useState("");
  const [slug, setSlug] = useState("");
  const [slugErrorMessage, setSlugErrorMessage] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const toast = useToast();

  const createCreator = useCallback(async () => {
    setDisplayNameErrorMessage("");
    setSlugErrorMessage("");
    setUserIdErrorMessage("");
    if (_.isEmpty(selectedUser)) {
      return;
    }
    if (_.isEmpty(displayName)) {
      setDisplayNameErrorMessage("顯示名稱不得空白");
      return;
    }
    if (_.isEmpty(slug)) {
      setSlugErrorMessage("創作者 ID 不得空白");
      return;
    }
    if (_.isEmpty(userId)) {
      setUserIdErrorMessage("使用者 ID 不得為空");
      return;
    }

    const creatorId = _.get(selectedUser, "creatorId", null);
    if (creatorId) {
      setUserIdErrorMessage("該使用者已經是創作者");
      return;
    }

    const response = await api.createCreator({
      displayName,
      slug,
      userId,
    });

    if (_.isString(response)) {
      const responseBody = JSON.parse(response);
      if (responseBody.error === "SLUG_USED") {
        setSlugErrorMessage("創作者 ID 已經被使用");
        return;
      }
      console.log(response);
    }

    toast({
      title: "創作者帳號已連結",
      isClosable: true,
      status: "success",
    });

    setDisplayName("");
    setSlug("");
    setUserId("");
    setSelectedUser(null);
  }, [selectedUser, displayName, slug, userId, api, toast]);

  const confirmUser = useCallback(async () => {
    setSearching(true);
    try {
      const user = await api.getUser(userIdentifier);
      console.log(user);
      if (user && !user?.error) {
        setSelectedUser(user);
        console.log(user);
        setUserId(user.id);
        onClose();
      } else {
        toast({
          title: "找不到指定使用者",
          isClosable: true,
          status: "error",
        });
      }
      setSearching(false);
    } catch (error) {
      console.log(error);
    }
  }, [api, userIdentifier, onClose, toast]);

  return (
    <>
      <MainLayout>
        <SimpleGrid paddingX={2} rowGap={2} marginTop={2}>
          <Card>
            <CardHeader>新增創作者</CardHeader>
            <CardBody>
              <SimpleGrid rowGap={4}>
                <FormControl isInvalid={!!displayNameErrorMessage}>
                  <FormLabel>創作者顯示名稱</FormLabel>
                  <Input
                    value={displayName}
                    onChange={(e) => setDisplayName(e.target.value)}
                  />
                  <FormErrorMessage>{displayNameErrorMessage}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!slugErrorMessage}>
                  <FormLabel>創作者 ID</FormLabel>
                  <Input
                    value={slug}
                    onChange={(e) => setSlug(e.target.value)}
                  />
                  <FormErrorMessage>{slugErrorMessage}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={!!userIdErrorMessage}>
                  <FormLabel>綁定使用者 ID</FormLabel>
                  <InputGroup size="md">
                    <Input
                      isDisabled={true}
                      paddingRight={24}
                      value={userId}
                      onChange={(e) => setUserId(e.target.value)}
                    />

                    <InputRightElement width={24}>
                      <Button
                        h="1.75rem"
                        size="sm"
                        marginRight={2}
                        onClick={onOpen}
                      >
                        查詢使用者
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <FormErrorMessage>{userIdErrorMessage}</FormErrorMessage>
                </FormControl>
              </SimpleGrid>
            </CardBody>
            <CardFooter>
              <Button colorScheme={"blue"} onClick={createCreator}>
                新增
              </Button>
            </CardFooter>
          </Card>
        </SimpleGrid>
      </MainLayout>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>搜尋使用者</ModalHeader>
          <ModalBody>
            <FormControl>
              <Input
                value={userIdentifier}
                onChange={(e) => setUserIndentifier(e.target.value)}
                placeholder="信箱/使用者ID/手機號碼"
              />
            </FormControl>
          </ModalBody>

          <ModalFooter>
            <Flex columnGap={2}>
              <Button onClick={onClose}>關閉</Button>
              <Button
                colorScheme={"orange"}
                onClick={confirmUser}
                isLoading={searching}
                loadingText={"查詢使用者中"}
              >
                確認使用者
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
