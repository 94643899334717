import _ from "lodash";
import { useFirebase } from "context/firebase.context";
import { Outlet } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";

export default function MustAdminLayout({ children }) {
  const { isAuthLoading, user } = useFirebase();
  const [isIdTokenLoading, setIsIdTokenLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  const loadUserIdToken = useCallback(async () => {
    if (user) {
      const idTokenResult = await user.getIdTokenResult();
      const isAdmin = _.get(idTokenResult, "claims.isAdmin", false);
      setIsIdTokenLoading(false);
      setIsAdmin();
      console.log(idTokenResult);
      if (!isAdmin) {
        window.location.replace("/auth/login");
      }
    } else if (!isAuthLoading && !user) {
      window.location.replace("/auth/login");
    }
  }, [user, isAuthLoading]);

  useEffect(() => {
    if (!isAuthLoading && (!user || !_.get(user, "roles.admin", false))) {
      loadUserIdToken();
    }
  }, [isAuthLoading, user, loadUserIdToken]);

  return (
    <>
      {!isIdTokenLoading && isAdmin && children}
      <Outlet />
    </>
  );
}
