import {
  Button,
  FormControl,
  Input,
  SimpleGrid,
  useToast,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import useAPI from "hooks/api";
import { useCallback, useRef } from "react";

export default function ClaimPreRegistrationPage() {
  const api = useAPI();
  const inputRef = useRef(null);
  const toast = useToast();
  const claim = useCallback(async () => {
    if (inputRef.current.value.length === 0) return;
    try {
      const { error = null } = await api.claimPreRegistrationReward({
        email: inputRef.current.value,
      });
      switch (error) {
        case null:
          toast({
            title: "獎勵已發出",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          break;
        case "EMAIL_IS_CLAIMED":
          toast({
            title: "獎勵已經發放過了",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          break;
        case "EMAIL_IS_NOT_LISTED":
          toast({
            title: "沒有參與過事前登錄",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          break;
        case "USER_NOT_REGISTERED":
          toast({
            title: "該用戶尚未註冊",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          break;
        default:
          toast({
            title: "未知的錯誤",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
          break;
      }
    } catch (error) {
      console.log(error);
    }
  }, [api, toast]);

  return (
    <MainLayout>
      <SimpleGrid spacing={2} paddingX={4} marginTop={4}>
        <FormControl>
          <Input placeholder="Email" ref={inputRef} />
          {/* <FormErrorMessage>We'll never share your email.</FormErrorMessage> */}
        </FormControl>
        <Button onClick={claim} colorScheme={"blue"}>
          發送獎勵
        </Button>
      </SimpleGrid>
    </MainLayout>
  );
}
