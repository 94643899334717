import {
  Box,
  Button,
  Center,
  Circle,
  CircularProgress,
  Flex,
  Input,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import FAIcon from "@components/FAIcon";
import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { ulid } from "ulid";
import { Link, useNavigate } from "react-router-dom";
import useAPI from "hooks/api";
import getPublicDownloadUrl from "libs/get-public-download-url";

export default function CreatorsIndexPage() {
  const api = useAPI();
  const navigate = useNavigate();
  const [creators, setCreators] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const filteredCreators = useMemo(
    () =>
      creators.filter((creator) => {
        if (!keyword) return true;
        const { id, displayName, slug } = creator;
        return (
          id.includes(keyword) ||
          displayName.includes(keyword) ||
          slug.includes(keyword)
        );
      }),
    [creators, keyword],
  );
  useEffect(() => {
    api.getCreators().then((result) => {
      setCreators(_.get(result, "data", []));
      setIsLoading(false);
    });
  }, [api]);

  return (
    <MainLayout>
      <SimpleGrid paddingX={2} rowGap={2} marginTop={2}>
        <Flex justifyContent="space-between" gap={2}>
          <Flex flex={1}>
            <Input
              value={keyword}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="暱稱 / ID"
            />
          </Flex>
          <Flex>
            <Link to="/creators/new">
              <Button colorScheme="blue">新增創作者</Button>
            </Link>
          </Flex>
        </Flex>
        <SimpleGrid spacing={2}>
          {isLoading && (
            <Center mt={6}>
              <CircularProgress isIndeterminate />
            </Center>
          )}
          {!isLoading &&
            filteredCreators.map((creator) => {
              return (
                <Box
                  onClick={() => {
                    const creatorId = _.get(creator, "id");
                    if (creatorId) {
                      navigate(`/creators/${creatorId}`);
                    }
                  }}
                  cursor={"pointer"}
                  key={_.get(creator, "id", ulid())}
                  backgroundColor={"gray.600"}
                  padding={4}
                  borderRadius={"md"}
                >
                  <Flex columnGap={4}>
                    <Circle
                      size={12}
                      backgroundColor={"black"}
                      backgroundImage={
                        _.get(creator, "picture")
                          ? `url('${getPublicDownloadUrl(_.get(creator, "picture"))}')`
                          : null
                      }
                      backgroundSize={"cover"}
                      backgroundPosition={"center"}
                    ></Circle>
                    <Box>
                      <Flex alignItems={"center"}>
                        <Text fontWeight={"semibold"}>
                          {_.get(creator, "displayName", "未命名的創作者")}{" "}
                          <Text as={"span"} color={"green.200"}>
                            <FAIcon group={"solid"} name="check-circle" />
                          </Text>
                        </Text>
                      </Flex>
                      <Text fontSize={"xs"}>
                        @{_.get(creator, "slug", "anonymouse")}
                      </Text>
                      <Box marginTop={4}>
                        {_.get(creator, "description", null)}
                      </Box>
                    </Box>
                  </Flex>
                  <SimpleGrid
                    marginTop={4}
                    columns={3}
                    borderTopWidth={"1px"}
                    borderTopColor={"gray.800"}
                    paddingTop={4}
                  >
                    <Flex
                      flexDirection={"column"}
                      rowGap={2}
                      alignItems={"center"}
                    >
                      <Text fontSize={"2xl"}>
                        {_.get(creator, "followersTotal", 0)}
                      </Text>
                      <Text fontSize={"sm"}>追蹤數</Text>
                    </Flex>
                    <Flex
                      flexDirection={"column"}
                      rowGap={2}
                      alignItems={"center"}
                    >
                      <Text fontSize={"2xl"}>
                        {_.get(creator, "videosTotal", 0)}
                      </Text>
                      <Text fontSize={"sm"}>上架影片數</Text>
                    </Flex>
                    <Flex
                      flexDirection={"column"}
                      rowGap={2}
                      alignItems={"center"}
                    >
                      <Text fontSize={"2xl"}>
                        {_.get(creator, "points.balance", 0)}
                      </Text>
                      <Text fontSize={"sm"}>鑽石數</Text>
                    </Flex>
                  </SimpleGrid>
                </Box>
              );
            })}
        </SimpleGrid>
      </SimpleGrid>
    </MainLayout>
  );
}
