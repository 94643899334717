import {
  Box,
  Button,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import FAIcon from "@components/FAIcon";
import { useFirebase } from "context/firebase.context";
import { ref, uploadBytes } from "firebase/storage";
import { useCallback, useMemo, useRef, useState } from "react";
import Delta from "quill-delta";
import getPublicDownloadUrl from "libs/get-public-download-url";
import { Link, useNavigate } from "react-router-dom";

const TAB_INDEX_MAPPING = {
  body: 0,
  link: 1,
  action: 2,
};

export default function AnnouncementsEditor({ onSend, annoucement = {} }) {
  const { storage } = useFirebase();
  const fileUploadRef = useRef(null);
  const navigate = useNavigate();
  const [title, setTitle] = useState(annoucement.title || "");
  const [type, setType] = useState("body");
  const [link, setLink] = useState(annoucement.link || "");
  const [target, setTarget] = useState(annoucement.target || "system");
  const [priority, setPriority] = useState(annoucement.priority || 0);
  const [attachment, setAttachment] = useState();

  const attachmentUrl = useMemo(() => {
    if (attachment) return URL.createObjectURL(attachment);
  }, [attachment]);

  const send = useCallback(async () => {
    if (!title || !target) return;
    const payload = { title, target, priority };
    if (type === "body") {
      const fileName = `announcement-${Date.now()}`;
      const path = `announcements/${fileName}.png`;
      const fileRef = ref(storage, path);
      await uploadBytes(fileRef, attachment);
      const delta = new Delta().insert(
        { image: getPublicDownloadUrl(path) },
        { alt: title },
      );
      payload.body = JSON.stringify(delta);
    } else if (type === "link") {
      payload.link = link;
    }
    try {
      await onSend(payload);

      navigate("/announcements");
    } catch (e) {
      console.error();
    }
  }, [
    navigate,
    onSend,
    attachment,
    link,
    priority,
    storage,
    target,
    title,
    type,
  ]);

  return (
    <VStack p={5} gap={4} align="stretch" maxW={600} mx="auto">
      <Box>
        <Link to="/announcements">
          <FAIcon name="arrow-left" fontSize="xl" role="button" />
        </Link>
      </Box>
      <Input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="標題"
      />

      <InputGroup>
        <InputLeftAddon>優先度</InputLeftAddon>
        <Input
          type="number"
          value={priority}
          onChange={(e) => setPriority(e.target.value)}
        />
      </InputGroup>
      <InputGroup>
        <InputLeftAddon>公告類型</InputLeftAddon>
        <Select value={target} onChange={(e) => setTarget(e.target.value)}>
          <option value="system">系統</option>
          <option value="operation">營運</option>
          <option value="users">NightCo 使用者</option>
          <option value="creators">NightCo 創作者</option>
        </Select>
      </InputGroup>

      <InputGroup>
        <InputLeftAddon>公告操作</InputLeftAddon>
        <Select value={type} onChange={(e) => setType(e.target.value)}>
          <option value="body">顯示活動頁(視覺圖)</option>
          <option value="link">跳轉外部連結</option>
          <option value="action">App 內動作</option>
        </Select>
      </InputGroup>

      <Tabs index={TAB_INDEX_MAPPING[type]}>
        <TabPanels>
          <TabPanel p={0}>
            <Input
              type="file"
              ref={fileUploadRef}
              onChange={(e) => setAttachment(e.target.files[0])}
              hidden
            />
            {attachment ? (
              <Box position="relative">
                <Image src={attachmentUrl} />
                <Box
                  role="button"
                  position="absolute"
                  right={-3}
                  top={-3}
                  onClick={() => setAttachment(null)}
                >
                  <FAIcon
                    group="solid"
                    name="circle-xmark"
                    fontSize="2xl"
                    color="red.400"
                  />
                </Box>
              </Box>
            ) : (
              <Box
                border="2px dotted white"
                width="100%"
                p={20}
                borderRadius={5}
                textAlign="center"
                flex={1}
                onClick={() => fileUploadRef.current.click()}
              >
                點擊上傳視覺圖
              </Box>
            )}
          </TabPanel>
          <TabPanel p={0}>
            <Input
              type="text"
              value={link}
              onChange={(e) => setLink(e.target.value)}
              placeholder="輸入外部連結"
            />
          </TabPanel>
          <TabPanel p={0}>
            {/* @todo: implementation */}
            <Text mt={3} align="center">
              尚未支援 🥵
            </Text>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Button
        onClick={send}
        mt={12}
        colorScheme="blue"
        isDisabled={
          !title ||
          !target ||
          (type === "body" && !attachmentUrl) ||
          (type === "link" && !link)
        }
      >
        發送
      </Button>
    </VStack>
  );
}
