import {
  Avatar,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import dayjs from "dayjs";
import useAPI from "hooks/api";
import getPublicDownloadUrl from "libs/get-public-download-url";
import _ from "lodash";
import { useCallback, useRef, useState } from "react";

export default function DepositSearchPage() {
  const api = useAPI();
  const inputRef = useRef(null);
  const toast = useToast();
  const [deposit, setDeposit] = useState(null);
  const search = useCallback(async () => {
    if (!inputRef.current) return;
    const depositId = inputRef.current.value.trim();
    if (!depositId) return;
    const response = await api.getDeposit(depositId);
    const { error = null } = response;
    if (!error) {
      setDeposit(response);
      return;
    }
    toast({
      title: "找不到指定儲值訂單",
      status: "error",
      duration: 2000,
      isClosable: true,
    });
  }, [api, setDeposit, toast]);

  return (
    <MainLayout>
      <SimpleGrid spacing={2} paddingX={4} marginTop={4}>
        <FormControl>
          <Input placeholder="訂單 ID" ref={inputRef} />
          {/* <FormErrorMessage>We'll never share your email.</FormErrorMessage> */}
        </FormControl>
        <Button onClick={search} colorScheme={"blue"}>
          搜尋
        </Button>
        {deposit && (
          <Box padding={4} backgroundColor={"gray.700"} borderRadius={"md"}>
            <SimpleGrid spacing={4}>
              <VStack alignItems="flex-start" mb={4}>
                <Text fontSize="lg">用戶資料</Text>
                <Flex alignItems="center" columnGap={2} my={3}>
                  <Avatar
                    src={getPublicDownloadUrl(
                      _.get(deposit, "user.picture", null)
                    )}
                    size="sm"
                    backgroundColor={"black"}
                  />
                  <Text>{_.get(deposit, "user.displayName", "--")}</Text>
                </Flex>
                <Flex alignItems="center" columnGap={2}>
                  <Text>用戶 ID： </Text>
                  <Text>{_.get(deposit, "user.id", "--")}</Text>
                </Flex>
                <Flex alignItems="center" columnGap={2}>
                  <Text>Email： </Text>
                  <Text>{_.get(deposit, "user.email", "--")}</Text>
                </Flex>
                <Flex alignItems="center" columnGap={2}>
                  <Text>手機： </Text>
                  <Text>{_.get(deposit, "user.phoneNumber", "--")}</Text>
                </Flex>
              </VStack>

              <FormControl>
                <FormLabel>訂單編號</FormLabel>
                <Input
                  readOnly={true}
                  isDisabled={true}
                  value={_.get(deposit, "id")}
                />
              </FormControl>
              <FormControl>
                <FormLabel>儲值金額</FormLabel>
                <Input
                  type="number"
                  readOnly={true}
                  isDisabled={true}
                  value={_.get(deposit, "price", 0)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>獲得鑽石</FormLabel>
                <Input
                  type="number"
                  readOnly={true}
                  isDisabled={true}
                  value={_.get(deposit, "points", 0)}
                />
              </FormControl>
              <FormControl>
                <FormLabel>儲值時間</FormLabel>
                <Input
                  readOnly={true}
                  isDisabled={true}
                  value={dayjs
                    .unix(_.get(deposit, "createdAt", "") / 1000)
                    .format("YYYY MM DD HH:mm:ss (dddd)")
                    .toString()}
                />
              </FormControl>
              <FormControl>
                <FormLabel>發票號碼</FormLabel>
                <Input
                  readOnly={true}
                  isDisabled={true}
                  value={_.get(deposit, "invoice.code", 0)}
                />
              </FormControl>
            </SimpleGrid>
          </Box>
        )}
      </SimpleGrid>
    </MainLayout>
  );
}
