import { useToast } from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import AnnouncementsEditor from "components/AnnoucementEditor";
import useAPI from "hooks/api";
import { useCallback } from "react";

export default function NewAnnouncementPage() {
  const api = useAPI();
  const toast = useToast();
  const createAnnouncement = useCallback(
    async (payload) => {
      await api.createNotification(payload);
      toast({
        title: "成功送出新公告",
        status: "success",
        duration: 2000,
      });
    },
    [api, toast],
  );
  return (
    <MainLayout>
      <AnnouncementsEditor onSend={createAnnouncement} />
    </MainLayout>
  );
}
