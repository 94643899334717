import dayjs from "dayjs";

const canonicalDate = (raw) => {
  const now = dayjs();
  const time = dayjs(raw);
  switch (true) {
    case time.isSame(now, "day"):
      return time.format("HH:mm");
    case now.diff(time, "day") <= 2:
      return "昨天";
    default:
      return time.format("MM/DD");
  }
};

export default canonicalDate;
