import {
  AspectRatio,
  Avatar,
  Box,
  Button,
  Center,
  Circle,
  CircularProgress,
  Fade,
  Flex,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Radio,
  RadioGroup,
  SimpleGrid,
  Spinner,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import AddItem from "components/AddItem";
import FAIcon from "components/FAIcon";
import VideoSelector from "components/VideoSelector";
import useAPI from "hooks/api";
import getPublicDownloadUrl from "libs/get-public-download-url";
import { cloneDeep } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { ref, uploadBytes } from "firebase/storage";
import { useFirebase } from "context/firebase.context";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

const Item = ({
  type,
  picture,
  displayName,
  thumbnails,
  thumbnail,
  onRemove,
}) => (
  <Box mt={3}>
    {type === "creators" ? (
      <VStack>
        <Box position="relative">
          <Avatar src={getPublicDownloadUrl(picture)} size="xl" />
          <Circle
            size={5}
            position="absolute"
            bg="red.400"
            top={1}
            role="button"
            right={1}
            onClick={onRemove}
          >
            <FAIcon name="close" />
          </Circle>
        </Box>
        <Text align="center" mt={1}>
          {displayName}
        </Text>
      </VStack>
    ) : (
      <Box width={80} position="relative">
        <AspectRatio ratio={9 / 16} borderRadius="md">
          <Image src={thumbnail || thumbnails} />
        </AspectRatio>
        <Circle
          size={5}
          position="absolute"
          bg="red.400"
          top={-2}
          role="button"
          right={-2}
          onClick={onRemove}
        >
          <FAIcon name="close" />
        </Circle>
      </Box>
    )}
  </Box>
);

const CreatorSelector = ({ creators, onSelect }) => (
  <SimpleGrid columns={3} gap={2} p={8}>
    {creators.map((creator) => (
      <VStack
        role="button"
        key={`selector-${creator.id}`}
        mb={3}
        onClick={() => onSelect(creator)}
      >
        <Avatar src={getPublicDownloadUrl(creator.picture)} size="xl" />
        <Text fontSize="xs">{creator.displayName}</Text>
      </VStack>
    ))}
  </SimpleGrid>
);

const Group = ({
  name,
  type,
  items,
  loading,
  onSort,
  onLoad,
  onNameChange,
  onRemove,
  onMove,
  onAddItem,
  onRemoveItem,
}) => {
  const [buffer, setBuffer] = useState(name || "");
  const [priceAsc, setPriceAsc] = useState(false);
  const [timeAsc, setTimeAsc] = useState(false);

  const isCreator = type === "creators";

  const onSortPrice = useCallback(() => {
    const updated = items.sort((a, b) =>
      priceAsc ? a.price - b.price : b.price - a.price
    );
    onSort(updated);
    setPriceAsc((state) => !state);
  }, [items, priceAsc, onSort]);
  const onSortTime = useCallback(() => {
    const updated = items.sort((a, b) =>
      timeAsc ? a.createdAt - b.createdAt : b.createdAt - a.createdAt
    );
    onSort(updated);
    setTimeAsc((state) => !state);
  }, [items, timeAsc, onSort]);

  // debounce name edit
  useEffect(() => {
    if (name === buffer) return;
    const timeout = setTimeout(() => onNameChange(buffer), 500);
    return () => clearInterval(timeout);
  }, [buffer, name, onNameChange]);

  return (
    <Box key={name} mb={10} maxW={1200} overflow="auto">
      <Flex align="center" gap={4} mb={4}>
        <VStack>
          <FAIcon
            role="button"
            onClick={onMove(-1)}
            name="chevron-up"
            color="gray.400"
          />
          <FAIcon
            role="button"
            onClick={onMove(1)}
            name="chevron-down"
            color="gray.400"
          />
        </VStack>
        <Input
          value={buffer}
          onChange={(e) => setBuffer(e.target.value)}
          maxW={100}
          size="lg"
        />
        {type === "videos" && (
          <>
            <Button onClick={onSortPrice} isDisabled={loading || !items}>
              <Text>價格：</Text>
              <Text>{priceAsc ? "低 → 高" : "高 → 低"}</Text>
            </Button>
            <Button onClick={onSortTime} isDisabled={loading || !items}>
              <Text>時間：</Text>
              <Text>{timeAsc ? "舊 → 新" : "新 → 舊"}</Text>
            </Button>
          </>
        )}

        <FAIcon
          role="button"
          onClick={onRemove}
          name="trash-can"
          color="red.400"
          fontSize="xl"
        />
      </Flex>

      <Flex gap={4} align="center" overflowX="auto" width="100%">
        {items == null ? (
          loading ? (
            <Spinner />
          ) : (
            <FAIcon
              role="button"
              onClick={onLoad}
              name="refresh"
              color="blue.400"
              fontSize="xl"
            />
          )
        ) : (
          <>
            <AddItem
              onClick={onAddItem}
              ratio={isCreator ? null : 9 / 16}
              width={isCreator ? 28 : 80}
              height={isCreator ? 32 : null}
              minW={isCreator ? null : 80}
              rounded="lg"
            />
            {items.map((item, index) => (
              <Item
                key={`${name}-${item.id}`}
                type={type}
                onRemove={onRemoveItem(index)}
                {...item}
              />
            ))}
          </>
        )}
      </Flex>
    </Box>
  );
};

const AddBanner = ({ onAdd }) => {
  const fileRef = useRef(null);
  const [draft, setDraft] = useState(null);

  const createDraft = useCallback(() => setDraft({ url: "", file: null }), []);

  const updateDraftImage = useCallback((e) => {
    const attachment = e.target.files[0];
    setDraft((state) => ({ ...state, file: attachment }));
  }, []);

  const image = useMemo(() => {
    if (!draft?.file) return null;
    return URL.createObjectURL(draft.file);
  }, [draft]);

  const addBanner = useCallback(async () => {
    if (!draft || !draft.file) return;

    await onAdd(draft);
    setDraft(null);
  }, [draft, onAdd]);

  return (
    <>
      <Modal isOpen={!!draft} onClose={() => setDraft(null)}>
        <ModalOverlay />
        <ModalBody>
          <ModalContent>
            {draft && (
              <Box p={6}>
                {image ? (
                  <Image src={image} mb={4} />
                ) : (
                  <Button onClick={() => fileRef.current.click()} mb={6}>
                    新增檔案
                  </Button>
                )}
                <Input
                  type="file"
                  accept="image/*"
                  ref={fileRef}
                  hidden
                  onChange={updateDraftImage}
                />
                <InputGroup>
                  <InputLeftAddon>網址</InputLeftAddon>
                  <Input
                    value={draft.url}
                    onChange={(e) =>
                      setDraft((state) => ({
                        ...state,
                        url: e.target.value,
                      }))
                    }
                  />
                </InputGroup>

                <Button
                  onClick={addBanner}
                  colorScheme="cyan"
                  width="100%"
                  mt={6}
                  isDisabled={!image}
                >
                  新增
                </Button>
              </Box>
            )}
          </ModalContent>
        </ModalBody>
      </Modal>

      <AddItem ratio={9 / 4} width="100%" onClick={createDraft} />
    </>
  );
};

export default function ExplorePage() {
  const { storage } = useFirebase();
  const [isDesktop] = useMediaQuery("(min-width: 992px)");
  const api = useAPI();
  const [settings, setSettings] = useState(null);
  const [creators, setCreators] = useState(null);
  const [draftGroup, setDraftGroup] = useState(null);
  const [editing, setEditing] = useState(null);
  const [updateStatus, setUpdateStatus] = useState("IDLE");

  const editingGroup = useMemo(
    () => settings?.groups?.[editing] || {},
    [editing, settings?.groups]
  );

  const openGroupEditModal = useCallback(
    (index) => () => setEditing(index),
    []
  );
  const closeEditModal = useCallback(() => setEditing(null), []);

  const createDraftGroup = useCallback(
    () => setDraftGroup({ name: "", type: "creators", tags: [] }),
    []
  );
  const apiCall = useCallback(
    (method) =>
      async (...params) => {
        setUpdateStatus("UPDATING");
        const data = await api[method](...params);
        setUpdateStatus("DONE");
        setTimeout(() => setUpdateStatus("IDLE"), 1000);
        return data;
      },
    [api]
  );

  const loadGroup = useCallback(
    (index) => async () => {
      let clone = cloneDeep(settings);
      clone.groups[index].loading = true;
      setSettings(clone);
      const { result } = await api.getExploreSettingsByChunk(index, 0, 99999);
      clone = cloneDeep(clone);
      delete clone.groups[index].loading;
      clone.groups[index].items = result.items;
      setSettings(clone);
    },
    [api, settings]
  );

  const addGroup = useCallback(async () => {
    const data = await apiCall("createExploreSettingsGroup")(draftGroup);

    setSettings((state) => ({
      ...state,
      groups: state.groups.concat(data),
    }));
    setDraftGroup(null);
  }, [apiCall, draftGroup]);

  const moveGroup = useCallback(
    (index) => (vector) => async () => {
      const clone = cloneDeep(settings);
      const target = index + vector;
      if (target < 0 || target >= clone.groups.length) return;
      await apiCall("swapExploreSettingsGroup")(index, target);
      const temp = clone.groups[index];
      clone.groups[index] = clone.groups[target];
      clone.groups[target] = temp;
      setSettings(clone);
    },
    [apiCall, settings]
  );

  const removeGroup = useCallback(
    (index) => async () => {
      await apiCall("removeExploreSettingsGroup")(index);

      const clone = cloneDeep(settings);
      clone.groups.splice(index, 1);

      setSettings(clone);
    },
    [apiCall, settings]
  );
  const editGroupName = useCallback(
    (index) => async (name) => {
      if (!name) return;
      await apiCall("updateExploreSettingsGroup")(index, {
        type: "update_name",
        payload: { name },
      });
      const clone = cloneDeep(settings);
      clone.groups[index].name = name;

      setSettings(clone);
    },
    [apiCall, settings]
  );
  const removeItem = useCallback(
    (groupIndex) => (itemIndex) => async () => {
      const clone = cloneDeep(settings);
      const item = clone.groups[groupIndex].items[itemIndex];
      await apiCall("updateExploreSettingsGroup")(groupIndex, {
        type: "remove_item",
        payload: { id: item.id },
      });
      clone.groups[groupIndex].items.splice(itemIndex, 1);

      setSettings(clone);
      setEditing(null);
    },
    [apiCall, settings]
  );
  const sortGroup = useCallback(
    (index) => async (items) => {
      const clone = cloneDeep(settings);
      await apiCall("updateExploreSettingsGroup")(index, {
        type: "arrange_items",
        payload: { items: items.map((item) => item.id) },
      });
      clone.groups[index].items = items;
      setSettings(clone);
    },
    [apiCall, settings]
  );

  const addItem = useCallback(
    async (newItem) => {
      await apiCall("updateExploreSettingsGroup")(editing, {
        type: "add_item",
        payload: { id: newItem.id },
      });
      const clone = cloneDeep(settings);
      clone.groups[editing].items.push(newItem);

      setSettings(clone);
      setEditing(null);
    },
    [apiCall, editing, settings]
  );

  const addBanner = useCallback(
    async (draft) => {
      const attachment = draft.file;
      const fileName = `explore-banner-${Date.now()}`;
      const image = `explore-banners/${fileName}.png`;
      const storageRef = ref(storage, image);
      await uploadBytes(storageRef, attachment);
      const banner = { image, url: draft.url || undefined };
      await apiCall("createExploreSettingsBanner")(banner);
      setSettings((state) => ({
        ...state,
        banners: state.banners.concat([banner]),
      }));
    },
    [apiCall, storage]
  );

  const removeBanner = useCallback(
    (index) => async () => {
      await apiCall("removeExploreSettingsBanner")(index);
      const clone = cloneDeep(settings);
      clone.banners.splice(index, 1);
      setSettings(clone);
    },
    [apiCall, settings]
  );

  const loadExploreSettings = useCallback(() => {
    api.getExploreSettings().then(setSettings);
  }, [api]);

  useEffect(() => {
    loadExploreSettings();
  }, [loadExploreSettings]);

  useEffect(() => {
    api.getCreators().then((result) => setCreators(result?.data));
  }, [api]);

  return (
    <MainLayout p={3}>
      {settings == null && (
        <Center mt={6}>
          <CircularProgress isIndeterminate size={12} />
        </Center>
      )}

      {settings && (
        <Box maxW={1200} mx="auto">
          <Box mb={8} p={6}>
            <Swiper
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
              }}
              pagination={{
                clickable: true,
              }}
              navigation={true}
              spaceBetween={30}
              loop
              modules={[Autoplay, Pagination, Navigation]}
            >
              {settings?.banners.map((banner, index) => (
                <SwiperSlide key={`banners-${index}`}>
                  <Box width="100%" position="relative">
                    <a
                      href={banner.url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Image
                        src={getPublicDownloadUrl(
                          typeof banner === "string" ? banner : banner.image
                        )}
                        width="100%"
                      />
                    </a>

                    <Circle
                      role="button"
                      onClick={removeBanner(index)}
                      size={6}
                      position="absolute"
                      bg="red.400"
                      top={4}
                      right={4}
                    >
                      <FAIcon name="close" />
                    </Circle>
                  </Box>
                </SwiperSlide>
              ))}
              <SwiperSlide>
                <AddBanner onAdd={addBanner} />
              </SwiperSlide>
            </Swiper>
          </Box>

          {settings.groups.map((group, index) => (
            <Group
              key={group.name}
              {...group}
              onLoad={loadGroup(index)}
              onNameChange={editGroupName(index)}
              onRemove={removeGroup(index)}
              onMove={moveGroup(index)}
              onRemoveItem={removeItem(index)}
              onAddItem={openGroupEditModal(index)}
              onSort={sortGroup(index)}
            />
          ))}
          <AddItem onClick={createDraftGroup} width={60} height={16} mt={12} />
        </Box>
      )}

      <Fade in={updateStatus !== "IDLE"} unmountOnExit>
        <Box position="fixed" bottom={0} right={0} p={4} bg="blackAlpha.500">
          {updateStatus === "UPDATING" && (
            <Flex align="center" gap={2}>
              更新中...
              <Spinner />
            </Flex>
          )}
          {updateStatus === "DONE" && (
            <Flex align="center" gap={2}>
              已同步
              <FAIcon name="check" />
            </Flex>
          )}
        </Box>
      </Fade>

      <Modal isOpen={!!draftGroup} onClose={() => setDraftGroup(null)}>
        <ModalOverlay />
        <ModalBody>
          <ModalContent>
            {draftGroup && (
              <Box p={6}>
                <Text fontSize="2xl" mb={6}>
                  新增探索群組
                </Text>
                <InputGroup>
                  <InputLeftAddon>名稱</InputLeftAddon>
                  <Input
                    value={draftGroup.name}
                    onChange={(e) =>
                      setDraftGroup((state) => ({
                        ...state,
                        name: e.target.value,
                      }))
                    }
                  />
                </InputGroup>
                <RadioGroup
                  value={draftGroup.type}
                  mt={4}
                  onChange={(type) =>
                    setDraftGroup((state) => ({
                      ...state,
                      type,
                    }))
                  }
                >
                  <Flex gap={4}>
                    <Radio value="creators">創作者</Radio>
                    <Radio value="videos">影片</Radio>
                  </Flex>
                </RadioGroup>
                <Button
                  onClick={addGroup}
                  colorScheme="cyan"
                  width="100%"
                  mt={6}
                  isDisabled={!draftGroup.name}
                >
                  新增
                </Button>
              </Box>
            )}
          </ModalContent>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={editing != null}
        onClose={closeEditModal}
        size={isDesktop ? "md" : "xs"}
      >
        <ModalOverlay />
        <ModalBody>
          <ModalContent>
            {editingGroup.type === "creators" && (
              <CreatorSelector creators={creators} onSelect={addItem} />
            )}
            {editingGroup.type === "videos" && (
              <VideoSelector onSelect={addItem} />
            )}
          </ModalContent>
        </ModalBody>
      </Modal>
    </MainLayout>
  );
}
