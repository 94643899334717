import {
  AspectRatio,
  Box,
  Circle,
  CircularProgress,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useMediaQuery,
  useToast,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import AddItem from "components/AddItem";
import FAIcon from "components/FAIcon";
import VideoSelector from "components/VideoSelector";
import useAPI from "hooks/api";
import { useCallback, useEffect, useState } from "react";

export default function GuestModePage() {
  const [isDesktop] = useMediaQuery("(min-width: 992px)");
  const api = useAPI();
  const [videos, setVideos] = useState(null);
  const [processing, setProcessing] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();

  const loadGuestModeVideos = useCallback(() => {
    api.getGuestModeVideos().then(setVideos);
  }, [api]);

  const addVideo = useCallback(
    async (video) => {
      setProcessing(true);
      onClose();
      const response = await api.addGuestModeVideo(video.id);

      if (response?.status === "SUCCESS") {
        loadGuestModeVideos();
        toast({ title: "新增成功", duration: 2000, status: "success" });
      } else {
        toast({ title: "新增失敗", duration: 2000, status: "error" });
      }
      setProcessing(false);
    },
    [api, loadGuestModeVideos, onClose, toast],
  );

  const removeVideo = useCallback(
    (id) => async () => {
      setProcessing(true);
      const response = await api.removeGuestModeVideo(id);
      if (response?.status === "SUCCESS") {
        loadGuestModeVideos();
        toast({ title: "移除成功", duration: 2000, status: "success" });
      } else {
        toast({ title: "移除失敗", duration: 2000, status: "error" });
      }
      setProcessing(false);
    },
    [api, loadGuestModeVideos, toast],
  );

  useEffect(() => {
    loadGuestModeVideos();
  }, [loadGuestModeVideos]);

  return (
    <MainLayout p={3}>
      <Flex gap={2} align="center">
        <Text fontSize="xl">影片列表</Text>
        {processing && <CircularProgress isIndeterminate size={6} />}
      </Flex>

      {videos != null ? (
        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={3} my={3}>
          {videos?.map((video) => (
            <Box position="relative" key={video.id}>
              <AspectRatio ratio={9 / 16}>
                <Image src={video.thumbnail} />
              </AspectRatio>
              <Circle
                size={5}
                position="absolute"
                bg="red.400"
                top={-2}
                role="button"
                onClick={removeVideo(video.id)}
                right={-2}
              >
                <FAIcon name="close" />
              </Circle>
            </Box>
          ))}
          <AddItem onClick={onOpen} ratio={9 / 16} rounded="lg" />
        </SimpleGrid>
      ) : (
        <CircularProgress isIndeterminate />
      )}
      <Modal isOpen={isOpen} onClose={onClose} size={isDesktop ? "md" : "xs"}>
        <ModalOverlay />
        <ModalBody>
          <ModalContent>
            <VideoSelector onSelect={addVideo} />
          </ModalContent>
        </ModalBody>
      </Modal>
    </MainLayout>
  );
}
