import { Box, Card, GridItem, SimpleGrid, Text } from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import RevenueChart from "components/RevenueChart";
import dayjs from "dayjs";
import useAPI from "hooks/api";
import { useEffect, useMemo, useState } from "react";

import React from "react";

export default function AnalyticsPage() {
  const api = useAPI();
  const [deposits, setDeposits] = useState(null);

  useEffect(() => {
    const updateData = async () => {
      const start = dayjs().subtract(15, "day").startOf("day").unix() * 1000;
      const data = await api.getDepositsAnalytics(start);
      if (Array.isArray(data)) {
        const today = dayjs().startOf("day");
        const grouped = data.reduce(
          (acc, cur) => {
            const slot = today.diff(
              dayjs(cur.updatedAt).startOf("day"),
              "days"
            );
            if (!acc[slot]) acc[slot] = [cur];
            else acc[slot].push(cur);
            return acc;
          },
          Array.from({ length: 15 }).map(() => [])
        );
        setDeposits(grouped);
      }
    };
    const interval = setInterval(updateData, 15000);

    const visibilityListener = () => !document.hidden && updateData();
    document.addEventListener("visibilitychange", visibilityListener);
    updateData();

    return () => {
      clearInterval(interval);
      document.removeEventListener("visibilitychange", visibilityListener);
    };
  }, [api]);

  const dailyRevenue = useMemo(
    () => deposits?.[0]?.reduce((acc, cur) => acc + cur.price, 0) || 0,
    [deposits]
  );

  const yesterdayRevenue = useMemo(
    () => deposits?.[1]?.reduce((acc, cur) => acc + cur.price, 0) || 0,
    [deposits]
  );

  const revenueGrowth = yesterdayRevenue
    ? (dailyRevenue - yesterdayRevenue) / yesterdayRevenue
    : 1;

  return (
    <MainLayout>
      <Box maxW={1200} mx="auto" mt={8} p={4}>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
          <Card p={4} textAlign="center">
            <Text my={2} fontWeight="semibold" color="gray.400">
              本日營收
            </Text>
            <Text fontSize="4xl" fontWeight="semibold" fontFamily="Outfit">
              {dailyRevenue.toLocaleString("en-US", {
                style: "currency",
                maximumFractionDigits: 0,
                currency: "USD",
              })}
            </Text>
            <Text
              color={revenueGrowth > 0 ? "green.600" : "red.600"}
              fontWeight="bold"
            >
              {revenueGrowth >= 0 && "+"}
              {(revenueGrowth * 100).toFixed(2)}%
            </Text>
          </Card>
          <Card display="flex" p={4} textAlign="center">
            <Text fontSize="2xl" m="auto">
              Coming Soon
            </Text>
          </Card>
          <Card display="flex" p={4} textAlign="center">
            <Text fontSize="2xl" m="auto">
              Coming Soon
            </Text>
          </Card>

          <GridItem colSpan={{ base: 1, md: 3 }}>
            <Card p={4} textAlign="center">
              <Text my={2} fontWeight="semibold" color="gray.400">
                15 日營收走勢圖
              </Text>
              <RevenueChart source={deposits} />
            </Card>
          </GridItem>
        </SimpleGrid>
      </Box>
    </MainLayout>
  );
}
