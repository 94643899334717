import { createContext, useContext, useEffect, useState } from "react";
import _ from "lodash-es";
import { useLocation } from "react-router-dom";

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [activePage, setActivePage] = useState("");
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const getInfo = (info, field, locale = "zh-TW", defaultValue = "") => {
    let targetLocale = _.get(info, locale, null);
    targetLocale = targetLocale ? targetLocale : _.get(info, locale, "zh-TW");
    return _.get(targetLocale, field, defaultValue);
  };

  const value = {
    getInfo,
    setActivePage,
    activePage,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export function useApp() {
  return useContext(AppContext);
}
