import { createContext, useCallback, useContext, useState } from "react";
import useAPI from "hooks/api";

const VideoSelectorContext = createContext({});

export const VideoSelectorProvider = ({ children }) => {
  const api = useAPI();
  const [videos, setVideos] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const load = useCallback(async () => {
    const lastVideo = videos[videos.length - 1];
    const response = await api.getVideos(lastVideo?.id);
    if (!response) return;
    if (!response.data.length) {
      setLoaded(true);
      return;
    }
    setVideos((state) => state.concat(response.data));
  }, [videos, api]);

  return (
    <VideoSelectorContext.Provider value={{ videos, load, loaded }}>
      {children}
    </VideoSelectorContext.Provider>
  );
};

export function useVideoSelector() {
  return useContext(VideoSelectorContext);
}
