import {
  Box,
  Center,
  Circle,
  CircularProgress,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputLeftAddon,
  Text,
  VStack,
} from "@chakra-ui/react";
import MainLayout from "@layouts/main.layout";
import useAPI from "hooks/api";
import { useCallback, useEffect, useMemo, useState } from "react";
import FAIcon from "components/FAIcon";
import { Link } from "react-router-dom";
import { isNumber } from "lodash";

const MODES = {
  USER: 0,
  CREATOR: 1,
};

const Pill = ({ active, onClick, children }) => (
  <Text
    role="button"
    fontSize="lg"
    bg={active ? "gray.600" : null}
    px={3}
    py={1}
    rounded="2xl"
    onClick={onClick}
  >
    {children}
  </Text>
);

export default function AnnouncementsPage() {
  const api = useAPI();
  const [announcements, setAnnoucements] = useState(null);
  const [mode, setMode] = useState(MODES.USER);

  const annoucementPreview = useMemo(() => {
    if (!announcements) return [];
    const filterOut = mode === MODES.USER ? "creators" : "users";
    const clone = announcements
      .slice()
      .filter((element) => element.target !== filterOut)
      .concat({ id: "normal-priority", priority: 0 });

    clone.sort((a, b) => b.priority - a.priority);

    return clone;
  }, [announcements, mode]);

  const loadAnnouncements = useCallback(() => {
    api.getNotifications().then((response) => setAnnoucements(response?.data));
  }, [api]);

  const changePriority = useCallback(
    (id) => async (e) => {
      const priority = +e.target.value;
      if (!isNumber(priority)) return;
      await api.updateNotification(id, { priority });
      loadAnnouncements();
    },
    [api, loadAnnouncements],
  );

  useEffect(() => {
    loadAnnouncements();
  }, [loadAnnouncements]);

  return (
    <MainLayout>
      <Heading align="center" my={4}>
        公告管理
      </Heading>

      <VStack p={5} gap={0} align="stretch" maxW={600} mx="auto">
        <Flex gap={3} justify="center" mb={5}>
          <Pill
            active={mode === MODES.USER}
            onClick={() => setMode(MODES.USER)}
          >
            用戶版面
          </Pill>

          <Pill
            active={mode === MODES.CREATOR}
            onClick={() => setMode(MODES.CREATOR)}
          >
            創作者版面
          </Pill>
        </Flex>
        {announcements == null && (
          <Center mt={6}>
            <CircularProgress isIndeterminate />
          </Center>
        )}
        {annoucementPreview.map(({ id, thumbnail, target, title, priority }) =>
          id !== "normal-priority" ? (
            <Box
              key={id}
              bg="#1C1914"
              borderBottomWidth={1}
              borderColor="#F6A01E"
              p={5}
            >
              <Flex gap={3}>
                <Box>
                  {thumbnail ? (
                    <Image src={thumbnail} />
                  ) : (
                    <Circle size="4rem" bg="#457AE4">
                      <FAIcon name="megaphone" group="solid" color="white" />
                    </Circle>
                  )}
                </Box>
                <Box flex={1}>
                  <Text color="orange.400" fontWeight="bold">
                    @{target}
                  </Text>
                  {title}
                </Box>
                <Flex alignSelf="center" alignItems="center" gap={2}>
                  <InputGroup size="sm">
                    <InputLeftAddon bg="cyan.600">優先度</InputLeftAddon>
                    <Input
                      type="number"
                      bg="white"
                      color="#212121"
                      p={2}
                      value={priority}
                      onChange={changePriority(id)}
                      width={8}
                    />
                  </InputGroup>

                  <Link to={`/announcements/${id}/edit`}>
                    <Circle bg="cyan.600" size={8}>
                      <FAIcon name="pen" fontSize="sm" />
                    </Circle>
                  </Link>
                </Flex>
              </Flex>
            </Box>
          ) : (
            <Flex key={id} height={300} bg="#F2901E">
              <Box m="auto" textAlign="center">
                <Text align="center" fontSize="xl" fontWeight="bold">
                  其他通知
                </Text>
                <FAIcon name="ellipsis" fontSize="3xl" />
              </Box>
            </Flex>
          ),
        )}
      </VStack>
      <Link to="/announcements/new">
        <Circle
          bg="cyan.600"
          position="fixed"
          size={{ base: 14, md: 20 }}
          bottom={{ base: 4, md: 8 }}
          right={{ base: 4, md: 8 }}
        >
          <FAIcon name="pen" fontSize={{ base: "2xl", md: "3xl" }} />
        </Circle>
      </Link>
    </MainLayout>
  );
}
