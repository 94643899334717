import { Text } from "@chakra-ui/react";

export default function FAIcon({
  sharp = false,
  group = "regular",
  name,
  ...props
}) {
  return (
    <Text
      as={"i"}
      className={`${sharp && "fa-sharp"} fa-${group} fa-${name}`}
      {...props}
    />
  );
}
