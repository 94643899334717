import {
  Box,
  Card,
  Flex,
  Radio,
  RadioGroup,
  Text,
  VStack,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import {
  BarChart,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  Bar,
  ResponsiveContainer,
} from "recharts";

const amounts = [
  99, 269, 299, 599, 999, 1999, 2099, 7499, 7999, 13999, 26999, 50999,
];
const colors = [
  "#c6ff00",
  "#c6ff00",
  "#c6ff00",
  "#7cb342",
  "#4caf50",
  "#2196f3",
  "#2196f3",
  "#7986cb",
  "#7986cb",
  "#9575cd",
  "#ffeb3b",
  "#eeeeee",
];

const CustomTooltip = ({ payload, active }) => {
  const total = payload.reduce((acc, cur) => acc + cur.payload[cur.dataKey], 0);
  const unit = payload[0]?.unit;
  return (
    active && (
      <Card p={2} bg="gray.500" color="black" rounded="md" textAlign="left">
        <Text mb={2} fontWeight="bold" color="#212121" align="center">
          {unit === "筆"
            ? `總筆數: ${total} 筆`
            : `總金額: ${total.toLocaleString("en-US", {
                style: "currency",
                maximumFractionDigits: 0,
                currency: "USD",
              })}`}
        </Text>
        {payload.map(({ color, dataKey, payload, unit }) => (
          <Box key={dataKey} color={color} fontWeight="semibold" fontSize="sm">
            {dataKey}: {payload[dataKey]} {unit} (
            {((payload[dataKey] / total) * 100).toFixed(1)}%)
          </Box>
        ))}
      </Card>
    )
  );
};

const RevenueChart = ({ source }) => {
  const [type, setType] = useState("amount");
  const data = useMemo(() => {
    if (!source) return [];
    return source
      .map((records, index) =>
        records.reduce(
          (acc, cur) => {
            const key = `$${cur.price}`;
            const value = type === "amount" ? 1 : cur.price;

            if (!acc[key]) acc[key] = value;
            else acc[key] += value;
            return acc;
          },
          { date: dayjs().subtract(index, "day").format("MM/DD") }
        )
      )
      .reverse();
  }, [source, type]);
  return (
    <VStack align="center" minH={300}>
      <RadioGroup onChange={setType} value={type}>
        <Flex gap={4} fontSize="sm">
          <Radio value="amount">以筆數區分</Radio>
          <Radio value="sum">以金額區分</Radio>
        </Flex>
      </RadioGroup>

      <ResponsiveContainer height={300} width="100%">
        <BarChart
          width={1200}
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            bottom: 5,
          }}
        >
          <XAxis dataKey="date" stroke="#e3e3e3" />
          <YAxis allowDecimals={false} stroke="#e3e3e3" />
          <Tooltip content={<CustomTooltip />} />
          <Legend />
          {amounts.map((amount, index) => (
            <Bar
              key={amount}
              dataKey={`$${amount}`}
              stackId="a"
              fill={colors[index]}
              barSize={20}
              unit={type === "amount" ? "筆" : "元"}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </VStack>
  );
};

export default RevenueChart;
